/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Convert contentstack markdown-formatted text to plain text (usually for SEO and SEO schemas)

export default function removeMarkdown(markdownText) {
    // line has more than just blanks and dashes in it?
    const lineHasContent = (line) => (!line.match(/^[\s-]*$/));

    // Is line one of those link references at bottom of text?  e.g. "[1]: https://www.harryandavld.tech/gift-and-towers"
    const lineIsLinkReference = (line) => (line.match(/^\s*\[\d+\]:\s+\S+$/));

    // remove basic styling and links from given line
    const filterOutStylingAndLinks = (line) => {
        let m;
        m = line.match(/^(.*?)\*\*\*(.*?)\*\*\*(.*)$/);  // bold + italic  ***
        if (m) return filterOutStylingAndLinks(m[1] + m[2] + m[3]);
        m = line.match(/^(.*?)\*\*(.*?)\*\*(.*)$/);  // bold  **
        if (m) return filterOutStylingAndLinks(m[1] + m[2] + m[3]);
        m = line.match(/^(.*?)\*(.*?)\*(.*)$/);  // italic  *
        if (m) return filterOutStylingAndLinks(m[1] + m[2] + m[3]);
        m = line.match(/^(.*?)\[(.*?)\]\[\d+\](.*)$/); // link     [link text][link number]
        if (m) return filterOutStylingAndLinks(m[1] + m[2] + m[3]);
        return line;
    };

    if (!markdownText) return markdownText;

    // chop text into separate lines
    let eol = '';
    if (markdownText.indexOf('\r\n') !== -1) {
        eol = '\r\n';
    } else if (markdownText.indexOf('\n') !== -1) {
        eol = '\n';
    }

    const lines = eol ? markdownText.split(eol) : [markdownText];

    // filter out all leading tokens --- headings, blockquotes; horizontal lines and blank lines
    const result = [];
    for (let i = 0; i < lines.length; i += 1) {
        let line = lines[i];
        if (line) {
            const m = line.match(/^[\s#>-]+(.*?)$/);  // strip leading blanks, #, >, -
            if (m) line = m[1];
            if (lineHasContent(line) && !lineIsLinkReference(line)) {
                result.push(filterOutStylingAndLinks(line));
            }
        }
    }

    return result.join(eol);
}
