/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const locations = {
    HD: [
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'P.O.Box', name: 'P.O.Box' },
        { id: 'APO/FPO', name: 'APO/FPO' },
    ],
    FLW: [
        { id: 'Apartment', name: 'Apartment' },
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'Church', name: 'Church' },
        { id: 'Funeral home', name: 'Funeral home' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'School', name: 'School' },
    ],
};

const address = {
    company: '',
    location: { id: '1', name: 'Residence' },
    street: '2350 Mission College Blvd',
    apt: '',
    city: 'Fremont',
    state: { id: 'CA', name: 'CA' },
    zipcode: '94536',
    country: { id: 'US', name: 'United States' },
};

export const holidayListData = [
    { id: '1', name: "Mother's Day Gifting" },
    { id: '2', name: "Father's Day Gifting" },
];

export const products = [
    {
        id: '1',
        image:
            'https://cdn1.harryandavld.tech/wcsstore/HarryAndDavid/images/catalog/18_5000_30XP_01ec.jpg',
        name: 'Classic Favorites Gift Basket',
        status: 'AVAILABLE TO SHIP NOW',
        price: '$69.99',
        discountPrice: '$69.99',
        crossedPrice: '$79.99',
        sku: '5000X',
        content: `
        <ul>
            <li>Approx. 6 Royal Verano® Pears (2 lb 13 oz)</li>
            <li>Moose Munch® Premium Popcorn - milk chocolate (6 oz)</li>
            <li>Sharp white cheddar cheese (4 oz)</li>
            <li>Three-seed crackers (4 oz)</li>
            <li>Pepper and Onion Relish (5 oz)</li>
            <li>Milk chocolate-covered cherries (3 oz)</li>
            <li>Signature chocolate truffles [coffee cherry raspberry almond all dark double chocolate] (4 oz)</li>
            <li>Decorative tin 11.75 in L x 8.5 in W x 4.25 in H (29.8 cm x 24.5 cm x 10.7 cm)</li>
            <li>Net Weight: 4 lb 7 oz</li>
        </ul>`,
    },
    {
        id: '2',
        image:
            'https://cdn1.harryandavld.tech/wcsstore/HarryAndDavid/images/catalog/18_12404_30XP_02ec.jpg',
        name: 'Deluxe Favorites Gift Basket',
        status: 'AVAILABLE TO SHIP NOW',
        price: '$89.99',
        sku: '12404X',
        content: `
        <ul>
            <li>Approx. 6 Royal Verano® Pears (2 lb 13 oz)</li>
            <li>Moose Munch® Premium Popcorn - milk chocolate (6 oz)</li>
            <li>Sharp white cheddar cheese (4 oz)</li>
            <li>Three-seed crackers (4 oz)</li>
            <li>Pepper and Onion Relish (5 oz)</li>
            <li>Milk chocolate-covered cherries (3 oz)</li>
            <li>Signature chocolate truffles [coffee cherry raspberry almond all dark double chocolate] (4 oz)</li>
            <li>Decorative tin 11.75 in L x 8.5 in W x 4.25 in H (29.8 cm x 24.5 cm x 10.7 cm)</li>
            <li>Net Weight: 4 lb 7 oz</li>
        </ul>`,
    },
    {
        id: '3',
        image:
            'https://cdn1.harryandavld.tech/wcsstore/HarryAndDavid/images/catalog/18_4202_30E_01ec.jpg',
        name: 'Grand Signature Gift Basket',
        status: 'AVAILABLE TO SHIP NOW',
        price: '$99.99',
        sku: '4202X',
        content: `
        <ul>
            <li>Approx. 6 Royal Verano® Pears (2 lb 13 oz)</li>
            <li>Moose Munch® Premium Popcorn - milk chocolate (6 oz)</li>
            <li>Sharp white cheddar cheese (4 oz)</li>
            <li>Three-seed crackers (4 oz)</li>
            <li>Pepper and Onion Relish (5 oz)</li>
            <li>Milk chocolate-covered cherries (3 oz)</li>
            <li>Signature chocolate truffles [coffee cherry raspberry almond all dark double chocolate] (4 oz)</li>
            <li>Decorative tin 11.75 in L x 8.5 in W x 4.25 in H (29.8 cm x 24.5 cm x 10.7 cm)</li>
            <li>Net Weight: 4 lb 7 oz</li>
        </ul>`,
    },
    {
        id: '4',
        image:
            'https://cdn1.harryandavld.tech/wcsstore/HarryAndDavid/images/catalog/18_5000_30XP_01ec.jpg',
        name: 'Classic Favorites Gift Basket',
        status: 'AVAILABLE TO SHIP NOW',
        price: '$69.99',
        sku: '4205X',
        content: `
        <ul>
            <li>Approx. 6 Royal Verano® Pears (2 lb 13 oz)</li>
            <li>Moose Munch® Premium Popcorn - milk chocolate (6 oz)</li>
            <li>Sharp white cheddar cheese (4 oz)</li>
            <li>Three-seed crackers (4 oz)</li>
            <li>Pepper and Onion Relish (5 oz)</li>
            <li>Milk chocolate-covered cherries (3 oz)</li>
            <li>Signature chocolate truffles [coffee cherry raspberry almond all dark double chocolate] (4 oz)</li>
            <li>Decorative tin 11.75 in L x 8.5 in W x 4.25 in H (29.8 cm x 24.5 cm x 10.7 cm)</li>
            <li>Net Weight: 4 lb 7 oz</li>
        </ul>`,
    },
    {
        id: '5',
        image:
            'https://cdn1.harryandavld.tech/wcsstore/HarryAndDavid/images/catalog/18_12404_30XP_02ec.jpg',
        name: 'Deluxe Favorites Gift Basket',
        status: 'AVAILABLE TO SHIP NOW',
        price: '$89.99',
        sku: '4206X',
        content: `
        <ul>
            <li>Approx. 6 Royal Verano® Pears (2 lb 13 oz)</li>
            <li>Moose Munch® Premium Popcorn - milk chocolate (6 oz)</li>
            <li>Sharp white cheddar cheese (4 oz)</li>
            <li>Three-seed crackers (4 oz)</li>
            <li>Pepper and Onion Relish (5 oz)</li>
            <li>Milk chocolate-covered cherries (3 oz)</li>
            <li>Signature chocolate truffles [coffee cherry raspberry almond all dark double chocolate] (4 oz)</li>
            <li>Decorative tin 11.75 in L x 8.5 in W x 4.25 in H (29.8 cm x 24.5 cm x 10.7 cm)</li>
            <li>Net Weight: 4 lb 7 oz</li>
        </ul>`,
    },
    {
        id: '6',
        image:
            'https://cdn1.harryandavld.tech/wcsstore/HarryAndDavid/images/catalog/18_4202_30E_01ec.jpg',
        name: 'Grand Signature Gift Basket',
        status: 'AVAILABLE TO SHIP NOW',
        price: '$99.99',
        sku: '4207X',
        content: `
        <ul>
            <li>Approx. 6 Royal Verano® Pears (2 lb 13 oz)</li>
            <li>Moose Munch® Premium Popcorn - milk chocolate (6 oz)</li>
            <li>Sharp white cheddar cheese (4 oz)</li>
            <li>Three-seed crackers (4 oz)</li>
            <li>Pepper and Onion Relish (5 oz)</li>
            <li>Milk chocolate-covered cherries (3 oz)</li>
            <li>Signature chocolate truffles [coffee cherry raspberry almond all dark double chocolate] (4 oz)</li>
            <li>Decorative tin 11.75 in L x 8.5 in W x 4.25 in H (29.8 cm x 24.5 cm x 10.7 cm)</li>
            <li>Net Weight: 4 lb 7 oz</li>
        </ul>`,
    },
];

export const addressBooks = [
    {
        id: '1',
        name: 'Dorothy Hodgkin',
        firstname: 'Dorothy',
        lastname: 'Hodgkin',
    },
    {
        id: '2',
        name: 'Lacey Root',
        firstname: 'Lacey',
        lastname: 'Root',
    },
    {
        id: '3',
        name: 'Edmond Halley',
        firstname: 'Edmond',
        lastname: 'Halley',
    },
    {
        id: '4',
        name: 'Edwin Powell Hubble',
        firstname: 'Edwin',
        lastname: 'Powell Hubble',
    },
    {
        id: '5',
        name: 'Bunchou Plong',
        firstname: 'Bunchou',
        lastname: 'Plong',
    },
];

export const recipientData = [
    {
        id: '1',
        firstname: 'Dorothy',
        lastname: 'Hodgkin',
        addToCart: '',
        type: 'link',
        occasionId: '2',
        giftArrived: '04/10/2018',
        address,
        products,
    },
    {
        id: '2',
        firstname: 'Lacey',
        lastname: 'Root',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    {
        id: '3',
        firstname: 'Edmond',
        lastname: 'Halley',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    {
        id: '4',
        firstname: 'Edwin',
        lastname: 'Powell Hubble',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    {
        id: '5',
        firstname: 'Elizabeth',
        lastname: 'Blackburn',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    {
        id: '6',
        firstname: 'Enrico',
        lastname: 'Fermi',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    {
        id: '7',
        firstname: 'Erwin',
        lastname: 'Schroedinger',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    {
        id: '8',
        firstname: 'Flossie',
        lastname: 'Wong-Staal',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    {
        id: '9',
        firstname: 'Frieda',
        lastname: 'Robscheit-Robbins',
        addToCart: '',
        type: 'link',
        occasionId: '1',
        address,
        products,
    },
    { id: '0', name: 'Add New Recipient', type: 'button' },
];

export default recipientData;
